@charset "UTF-8";
.logofooter {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 12.5rem;
  min-width: 12.5rem;
  height: auto;
  padding: 0 0 0 0;
}

ul {
  list-style: none;
  padding-left: 0;
}

footer {
  background-color: black;
  color: #bbb;
  line-height: 1.5;
}

footer a {
  text-decoration: none;
  color: #eee;
}

a:hover {
  text-decoration: underline;
}

.title {
  color: #fff;
  font-family: ’Merriweather’, serif;
  font-size: 1.375rem;
  padding-bottom: 0.625rem;
  border-bottom: 1.5px #777 solid;
}

body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  /* same as flex-grow: 1; */
}

.ft-main {
  padding: 1.25rem 1.875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.main-item {
  padding: 1.25rem;
  min-width: 12.5rem;
  text-align: left;
  max-width: 22.0rem;
  font-size: larger;
}

.main-item a:hover {
  text-decoration: none;
  color: #d11717;
}

@media only screen and (min-width: 29.8125rem) {
  .ft-main {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}

@media only screen and (min-width: 77.5rem) {
  .ft-main {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }
}

.social {
  padding: 0.9375rem 1.875rem;
}

.social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-top: 1px #777 solid;
  padding-top: 25px;
}

.social-list a:hover {
  text-decoration: none;
  color: #d11717;
}

.social-list li {
  margin: 0.5rem;
  font-size: 1.25rem;
}

.legal {
  padding: 0.9375rem 1.875rem;
  background-color: black;
  border-top: 1px #777 solid;
}

.legal-list {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.legal-list li {
  margin: 0.125rem 0.625rem;
  white-space: nowrap;
}

.legal-list a:hover {
  text-decoration: none;
  color: #d11717;
}

/* one before the last child */
.legal-list li:nth-last-child(2) {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  /* same as flex-grow: 1; */
}

.paragraph {
  font-size: 15px;
  padding: 0 0 0 0;
  text-justify: left;
  color: #eee;
}
