.wrapper-services {
    // margin: 50px 0 0 0;
    //border: 5px solid rgb(0, 158, 53);
    padding-top: 4.5%;
    height: fit-content;
    align-items: center;
    align-content: center;
    @media only screen and (max-width: 480px){
        padding-top: 15%;
    }
}
.section-1 {
    // border: 5px solid rgb(0, 158, 53);
    display: flex;
    flex-flow: column wrap;
}
.services-headline {
    padding: 5% 0 0 0;
    text-align: center;
    font-size: 75px;
    order: 1;
    @media only screen and (max-width: 480px){
        font-size: 60px;
    }
}
.subheadline {
    text-align: center;
    order: 2;
}
.subsection-1{
    order: 3;
    display: flex;
    flex-flow: column nowrap;
    // border: 5px solid rgb(158, 0, 0);
    align-items: center;
    @media only screen and (max-width: 480px){
        flex-flow: column nowrap;
    }
}
.sub-item-1 {
    // border: 5px solid rgb(0, 0, 0);
    order: 1;
    // display: flex;
    width: 50%;
    text-align: center;

    @media only screen and (max-width: 480px){
        width: 100%;
    }
}
.sub-item-2 {
    order: 2;

    display: flex;
    flex-flow: column nowrap;
    // border: 5px solid rgb(132, 0, 158);
    width: 100%;
    padding: 2% 0 0 0;
    background-color: rgb(88, 88, 88);

    @media only screen and (max-width: 480px){
        width: 100%;
    }
}
.services-paragraph-1{
    
    color: white;
    order: 1;
    align-self: flex-end;
    padding: 5%;
    text-align: justify;
    font-size: 35px;
    //border: 5px solid rgb(0, 158, 53);
    // align-self: flex-end;
    @media only screen and (max-width: 480px){
        font-size: 20px;
    }
}
.getQuote {
    // margin: 0 50px 0 0;
    margin-top: 10%;
    order: 2;
    width: 50%;
    align-self: center;
    font-size: 18px;
    //border: 5px solid rgb(0, 158, 53);
}
.image {
    order: 1;
    // border: 5px solid rgb(0, 158, 53);
    width: 100%;
}
.section-2{
    border: 5px solid rgb(0, 158, 53);
    display: flex;
    flex-flow: column wrap;
    margin-top: 0%;
}
.accordion{
    order: 1;
    text-align: center;
    width: 100%;
}

.section-3{
    // border: 5px solid rgb(0, 11, 158);
    display: flex;
    flex-flow: row nowrap;
    background-color: bisque;
    justify-content: center;
    @media only screen and (max-width: 480px){
        flex-flow: column nowrap;
    }
    // margin-top: 5%;
}
.services-paragraph-2{
    color: black;
    // align-self: center;
    padding: 0% 5% 0 5%;
    text-align: justify;
    font-size: 30px;
    // width: 100%;
    // border: 5px solid rgb(0, 11, 158);
}
.sec-item-1{
    order: 1;
    width: 50%;
    padding: 5%;
    // padding: 12% 0 0 0;
}
.sec-item-2{
    order: 2;
    width: 50%;
    border: 5px solid rgb(0, 11, 158);
}
.section-4{
    // border: 5px solid rgb(0, 11, 158);
    display: flex;
    flex-flow: column wrap;
    padding-top: 3%;
    background-color: rgb(204, 204, 204);
    padding-bottom: 3%;
    height: fit-content;
    justify-content: space-evenly;

    @media only screen and (max-width: 480px){
        flex-flow: column nowrap;
        height: 100vh;
    }

}
.sub-section-4{
    //border: 5px solid rgb(0, 11, 158);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 5%;

    @media only screen and (max-width: 480px){
        flex-flow: column nowrap;
        justify-content: space-evenly;
        // border: 5px solid rgb(0, 11, 158);
        height: 75vh;
    }
}
.sub-section-4 a {
    text-decoration: none;
    color: black;
}
a :hover {
    color: red;
}
.work-Headline{
    text-align: center;
    font-size: 30px;
    padding: 0 5% 0 5%;
    //border: 5px solid rgb(0, 158, 84);
    @media only screen and (max-width: 480px){
        font-size: 25px;
    }
}
.photo-gallery{
    // color: black;
    // align-self: flex-end;
    // padding: 5%;
    // text-align: center;
    // font-size: 36px;
    //border: 5px solid rgb(158, 32, 0);
    height: 500px;
}
.photo{
    height: fit-content;
    width: fit-content;
}
.section-5{
    border: 5px solid rgb(158, 0, 132);
    background-color: chocolate;
    padding-top: 5%;
    display: flex;
    flex-flow: column nowrap;
}
.testimonials-Headline{
    border: 5px solid rgb(158, 0, 132);

    text-align: center;
    order: 1;
}
.testimonials{
    order: 2;
    align-self: center;
    border: 5px solid rgb(0, 11, 158);
    width: max-content;
    // color: black;
    display: flex;
    // text-align: center;
    // font-size: 36px;
}
// .slide{
    
    
// }
// .subsection-5{
    
// }
.subsection-5-item-1{
    order: 1;
    width: 50%;
    text-align: center;
}
.subsection-5-item-2{
    order: 2;
    width: 50%;
    text-align: center;
}