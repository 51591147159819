.uiform {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
}

.error {
  color: red;
}

.wrapper-1-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  height: 100vh;
}

@media only screen and (max-width: 480px) {
  .wrapper-1-contact {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

.contact-title {
  font-size: 60px;
  text-align: left;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 480px) {
  .contact-title {
    font-size: 40px;
    text-align: center;
    margin: 0 0 0 0;
    padding: 25% 0 0 0;
  }
}

.contact-paragraph {
  font-size: 25px;
  text-align: left;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 480px) {
  .contact-paragraph {
    padding: 5%;
    font-size: 20px;
    text-align: center;
  }
}

.contact-icon {
  height: 300px;
}

.contact-icon-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  width: 100%;
}

.icon-social {
  font-size: 35px;
  width: auto;
  padding-top: 50px;
  text-decoration: none;
}

.item-1-contact {
  background-color: #d8d8d8;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  padding: 0 10% 0 10%;
  max-width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

@media only screen and (max-width: 480px) {
  .item-1-contact {
    color: black;
    background-color: #d8d8d8;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    min-width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
    padding: 10%;
  }
}

.item-2-contact {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  text-decoration: none;
  width: 50%;
}

@media only screen and (max-width: 480px) {
  .item-2-contact {
    min-width: 100%;
    height: 100vh;
  }
}

.item-2-contact a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: red;
}
