.uiform{
    align-items: center;
    align-content: center;
    // padding: 10%;
    // margin: 25px 2% 2% 50%;
    //border: 5px solid rgb(158, 0, 0);
}
.error{
    color: red;
}

.wrapper-1-contact {

    display: flex;
    flex-flow: row wrap;
    // height: 100%;
    height: 100vh;
    
    // border: 5px solid rgb(255, 4, 4);

    @media only screen and (max-width: 480px){
        flex-flow: column nowrap;
        height: fit-content;
    }

}
.contact-title {
    // border: 5px solid rgb(255, 4, 4);
    font-size: 60px;
    text-align: left;
    margin: 0;
    padding: 0;

    @media only screen and (max-width: 480px){
        font-size: 40px;
        text-align: center;
        margin: 0 0 0 0;
        padding: 25% 0 0 0;
    }
}
.contact-paragraph {
    font-size: 25px;
    text-align: left;
    margin: 0;
    padding: 0;
    @media only screen and (max-width: 480px){
        padding: 5%;
        font-size: 20px;
        text-align: center;
    }
}
.contact-icon{
    
    height: 300px;
}
.contact-icon-list {

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    // align-content:space-around;
    // border: 5px solid rgb(0, 158, 53);
    height: 100%;
    width: 100%;
}
.icon-social{
    // height: 50%;
    font-size: 35px;
    width: auto;
    padding-top: 50px;
    text-decoration: none;
    //border: 5px solid rgb(0, 158, 53);
    // size: 30%;
}

// li {
//     padding: 10%;
//     font-size: 175%;
// }
.item-1-contact {
    //background: linear-gradient( to right, rgb(255, 255, 255), rgb(95, 95, 95));
    background-color: rgb(216, 216, 216);

    order: 1;
    // border: 5px solid rgb(0, 158, 53);
    // flex-grow: 1;
    padding: 0 10% 0 10%;
    max-width: 50%;

    display: flex;
    flex-flow: column nowrap;
    justify-content:space-around;

    @media only screen and (max-width: 480px){
        color: black;
        background-color: rgb(216, 216, 216);
        order: 1;
        min-width: 100%;
        align-items: center;
        align-content: center;
        padding: 10%;
        // border: 5px solid rgb(0, 158, 53);
    }
}

.item-2-contact{
    order: 2;
    // border: 5px solid rgb(65, 68, 214);
    // flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    justify-content: space-evenly;
    text-decoration: none;

    width: 50%;
    // max-width: 50%;
    // box-shadow: 15px 10px;

    @media only screen and (max-width: 480px){
        min-width: 100%;
        //border: 5px solid rgb(65, 68, 214);
        height: 100vh;
    }
}
.item-2-contact a {
    text-decoration: none;
    color: rgb(0, 0, 0);
}
a:hover {
    color: rgb(255, 0, 0);
}
