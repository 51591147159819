.landingContainer{
    // background: linear-gradient( to right, rgb(255, 255, 255), rgb(95, 95, 95));
    background-color: rgb(216, 216, 216);

    height: 100vh;
    display: flex;
    flex-flow: row wrap;
    //padding-top: 50px;
    // padding-top: 47px;
    // border: 5px solid rgb(158, 0, 0);
    @media only screen and (max-width: 480px){
        height: fit-content;
    }
}
.item-1 {
    order: 1;
    // border: 5px solid rgb(0, 158, 53);
    // flex-grow: 1;
    padding: 12% 7% 12% 7%;
    max-width: 50%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    @media only screen and (max-width: 480px){
        max-width: 100%;
        order: 2;
        text-align: center;
        padding: 7% 15% 15% 15%;
    }
}

.item-2 {
    order: 2;
    //border: 5px solid rgb(65, 68, 214);
    flex-grow: 1;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    // padding: 5% 0% 0 0;
    // width: 20%;
    @media only screen and (max-width: 480px){
        order: 1;
        align-items: center;
        align-content: center;
        padding: 7% 0 0 0;
        height: fit-content;
        // border: 5px solid rgb(65, 68, 214);
    }
}
.logo {
    width: 100%;
    align-items: center;
    text-align: center;
    // border: 5px solid rgb(65, 87, 214);
    // margin: 0% 0 0 5%;
}
.landing-headline{
    font-size: 75px;
    text-align: left;
    margin: 0;
    padding: 0;
    @media only screen and (max-width: 480px){
        font-size: 40px;
        text-align: left;
        margin: 0;
        padding: 0;
        width: 100%;
        // border: 5px solid rgb(65, 87, 214);
    }
}

.subHeadline {
    font-size: 20px;
    @media only screen and (max-width: 480px){ 
        padding-top: 10%;
        text-align: justify;
        font-size: 18px;
        // border: 5px solid rgb(65, 87, 214);
    }
}
.moreButton{
    @media only screen and (max-width: 480px){ 
        padding-top: 5%;
        // border: 5px solid rgb(65, 87, 214);
    }
}