.wrapper-services {
  padding-top: 4.5%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
}

@media only screen and (max-width: 480px) {
  .wrapper-services {
    padding-top: 15%;
  }
}

.section-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column wrap;
          flex-flow: column wrap;
}

.services-headline {
  padding: 5% 0 0 0;
  text-align: center;
  font-size: 75px;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

@media only screen and (max-width: 480px) {
  .services-headline {
    font-size: 60px;
  }
}

.subheadline {
  text-align: center;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

.subsection-1 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (max-width: 480px) {
  .subsection-1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
  }
}

.sub-item-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  width: 50%;
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .sub-item-1 {
    width: 100%;
  }
}

.sub-item-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  width: 100%;
  padding: 2% 0 0 0;
  background-color: #585858;
}

@media only screen and (max-width: 480px) {
  .sub-item-2 {
    width: 100%;
  }
}

.services-paragraph-1 {
  color: white;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  -ms-flex-item-align: end;
      align-self: flex-end;
  padding: 5%;
  text-align: justify;
  font-size: 35px;
}

@media only screen and (max-width: 480px) {
  .services-paragraph-1 {
    font-size: 20px;
  }
}

.getQuote {
  margin-top: 10%;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  width: 50%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  font-size: 18px;
}

.image {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  width: 100%;
}

.section-2 {
  border: 5px solid #009e35;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column wrap;
          flex-flow: column wrap;
  margin-top: 0%;
}

.accordion {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  text-align: center;
  width: 100%;
}

.section-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  background-color: bisque;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media only screen and (max-width: 480px) {
  .section-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
  }
}

.services-paragraph-2 {
  color: black;
  padding: 0% 5% 0 5%;
  text-align: justify;
  font-size: 30px;
}

.sec-item-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  width: 50%;
  padding: 5%;
}

.sec-item-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  width: 50%;
  border: 5px solid #000b9e;
}

.section-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column wrap;
          flex-flow: column wrap;
  padding-top: 3%;
  background-color: #cccccc;
  padding-bottom: 3%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

@media only screen and (max-width: 480px) {
  .section-4 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    height: 100vh;
  }
}

.sub-section-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding-top: 5%;
}

@media only screen and (max-width: 480px) {
  .sub-section-4 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    height: 75vh;
  }
}

.sub-section-4 a {
  text-decoration: none;
  color: black;
}

a :hover {
  color: red;
}

.work-Headline {
  text-align: center;
  font-size: 30px;
  padding: 0 5% 0 5%;
}

@media only screen and (max-width: 480px) {
  .work-Headline {
    font-size: 25px;
  }
}

.photo-gallery {
  height: 500px;
}

.photo {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.section-5 {
  border: 5px solid #9e0084;
  background-color: chocolate;
  padding-top: 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
}

.testimonials-Headline {
  border: 5px solid #9e0084;
  text-align: center;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.testimonials {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  -ms-flex-item-align: center;
      align-self: center;
  border: 5px solid #000b9e;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.subsection-5-item-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  width: 50%;
  text-align: center;
}

.subsection-5-item-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  width: 50%;
  text-align: center;
}
