// .footer {
//     background-color: rgb(46, 46, 46);
//     position: relative;
//     bottom: 0;
//     width: 100%;
//     height: auto; 
//     display: flex;
//     justify-content: space-around;
//     color: white;
// //    border: 5px solid rgb(7, 255, 255); 
//     padding: 10px 0 10px 0;
// }



.logofooter {
    align-items: center;
    // margin-top: 10px;
    max-width: 12.5rem;
    min-width: 12.5rem;
    height: auto;   
    // border: 5px solid rgb(166, 255, 1);
    padding: 0 0 0 0;
}

// {
//     box-sizing: border-box;
//     font-family: ’Lato’, sans-serif;
//     margin: 0; 
//     padding: 0;
// }
ul {
    list-style: none;
    padding-left: 0;
}
footer {
    background-color: rgb(0, 0, 0);
    color: #bbb;
    line-height: 1.5;
}
footer a {
    text-decoration: none;
    color: #eee;
}
a:hover {
    text-decoration: underline;
}
.title {
    color: #fff;
    font-family: ’Merriweather’, serif;
    font-size: 1.375rem;
    padding-bottom: 0.625rem;
    border-bottom: 1.5px #777 solid; 
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}
.container {
    flex: 1;    /* same as flex-grow: 1; */
}

.ft-main {
    padding: 1.25rem 1.875rem;
    display: flex;
    flex-wrap: wrap;
}
.main-item {
    padding: 1.25rem;
    min-width: 12.5rem /*200px*/;
    text-align: left;
    max-width: 22.0rem;
    font-size: larger;
}
.main-item a:hover {
    text-decoration: none;
    color: rgb(209, 23, 23);
}
// body {
//     display: flex;
//     min-height: 100vh;
//     flex-direction: column;
// }
// .container {
//     flex: 1;    /* same as flex-grow: 1; */
// }

@media only screen and (min-width: 29.8125rem /*477px*/) {
    .ft-main {
        justify-content: space-around;
    }
}
@media only screen and (min-width: 77.5rem /*1240px*/ ) {
    .ft-main {
        justify-content: space-evenly;
    }
}

.social {
    padding: 0.9375rem 1.875rem;
}
.social-list {
    display: flex;
    justify-content: center;
    border-top: 1px #777 solid; 
    padding-top: 25px;
}
.social-list a:hover {
    text-decoration: none;
    color: rgb(209, 23, 23);
}
.social-list li {
    margin: 0.5rem;
    font-size: 1.25rem;
}

.legal {
    padding: 0.9375rem 1.875rem;
    background-color: rgb(0, 0, 0);
    border-top: 1px #777 solid; 
}
.legal-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.legal-list li {
    margin: 0.125rem 0.625rem;
    white-space: nowrap;
}
.legal-list a:hover {
    text-decoration: none;
    color: rgb(209, 23, 23);
}
/* one before the last child */
.legal-list li:nth-last-child(2) {
    flex: 1;       /* same as flex-grow: 1; */
}

.paragraph {
    font-size: 15px;
    // border: 5px solid rgb(7, 255, 255);
    padding: 0 0 0 0;
    text-justify: left;
    color: #eee;
}
